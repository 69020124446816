import React from "react";
import axios from 'axios';
import ReactDOM from "react-dom";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mjson from '../../local-json/Common';
import $ from 'jquery';

const SignupSchema = yup.object().shape({
  otp_code: yup.string().required('OTP Code Required'),
});
 
  let hideEmail = function(email) {
      return email.replace(/(.{1})(.*)(?=@)/,
        function(gp1, gp2, gp3) { 
          for(let i = 0; i < gp3.length; i++) { 
            gp2+= "*"; 
          } return gp2; 
        });
  };
  //localStorage.removeItem('reg_response')
  

function OtpComponent()
{
    let jobj=JSON.parse(localStorage.getItem('reg_response'));
    if(jobj!=null)
    {
      $('#hemail').text(hideEmail(jobj.email))  
    }
    let [apiUrl, setapiUrl] = React.useState(0);
    let [loginUrl, setLoginUrl] = React.useState(0);
    React.useEffect(() => 
    {
      if(Object.keys(mjson).length>0 && mjson.hasOwnProperty('api_link'))
      {
        setapiUrl(mjson.api_link)
      }
      if(Object.keys(mjson).length>0 && mjson.hasOwnProperty('loginlink'))
      {
        setLoginUrl(mjson.loginlink)
      }
    },[])
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState,
        formState: { errors }
      } = useForm({
        resolver: yupResolver(SignupSchema)
      });
      let { isSubmitting } = formState;
      
      const onSubmit = async(data) => 
      {
        if(jobj==null)
        {
          toast.error('', {
            position: toast.POSITION.TOP_RIGHT
           });
           navigate('/');
          return false; 
        }
        const newPost = {
          email:jobj.email,
          user_id:jobj.data.id,
          sub_id:jobj.data.sub,
          code:data.otp_code
        };
        try 
        {
          const resp = await axios({
            method:'post',
            'url':apiUrl+'mainsite/verify/email',
            responseType:'json',
            headers: 
            {
              'Access-Control-Allow-Origin': '*',
            },
            data:newPost
          })
          .then(function (response) 
          {
            isSubmitting=true;
            console.log(response.data.type)
            if(response.data.type=='success')
            {
              toast.success(response.data.message, {
               position: toast.POSITION.TOP_RIGHT
              });
              localStorage.removeItem('reg_response');
              window.open(loginUrl, "_blank")
              
              const timeId = setTimeout(() => {
                isSubmitting=false;
                navigate('/');
              },5000)
              
            }
            else
            {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT
               });
               isSubmitting=false;
            }
          }).catch(function (error){
            console.log(error);
            if(error.response.hasOwnProperty('data') && error.response.data.code=='404')
            {
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
               });
               isSubmitting=false;
            }
          });
          
        } 
        catch (err) 
        {
          console.error(err);
        }
      };
      
      
      
    return(
        <main id="main">
          <ToastContainer />
            <section id="signup" class="signup section">
                <div class="container" data-aos="fade-up">
                <div class="section-title">
                    <h2>OTP Verification</h2>
                </div>
                <div class="col-lg-7 mt-lg-0 d-flex me-auto ms-auto">
                    <form onSubmit={handleSubmit(onSubmit)} class="signupForm">
                    
                    <div class="form-group">
                    <h3 class="text-center">Please enter the OTP to verify your account.</h3>
                    <h4 class="text-center">A code has been send to</h4>
                    <p id='hemail'></p>
                        <input type="text" class="form-control" name="otp_code" id="otp_code" {...register("otp_code")} />
                        {errors.otp_code && <p className="p-error">{errors.otp_code.message}</p>} 
                    </div>
                    <div class="kd-3">
                        <div class="loading">Loading</div>
                        <div class="error-message"></div>
                        <div class="sent-message">Your message has been sent. Thank you!</div>
                    </div>
                    <div class="text-center">
                        <button disabled={isSubmitting} type="submit">
                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}  
                            Submit
                        </button>
                        
                    </div>
                    </form>
                </div>
                </div>
            </section>
        </main>
    );
}
export default OtpComponent;