
const HomeCubeComponent=(props)=>{
    
    return(
        <div className={props.clName}>
        <div className="icon-box">
            <img src={props.imagePath} className="img-fluid" alt="Number of active projects and active users" />
          <p>{props.description}</p>
        </div>
      </div> 
    );
}
export default HomeCubeComponent;