import React,{useEffect} from 'react';
import RouteSection from '../routes/RouterSection';
import '../../assets/css/style.css';
import $ from 'jquery';
function App()
{
    
    
    return(
        <div className="wrapper"> 
           <RouteSection />
        </div>
    );
}
export default App;