import ReactStars from "react-rating-stars-component"
const SliderComponent=(props)=>{
    
    const firstExample = {
        size: 30,
        value:Number(props.stars),
        edit: false
      };
    return(
        <div className="">
            <div className="testimonial-wrap">
            <h3>{props.cname}</h3>
            <h4>{props.info}<br />
                <br />
            </h4>
            <div class="star-rating">
                
                {/* <span className="bi bi-star-fill" data-rating="1"></span>
                <span className="bi bi-star-fill" data-rating="2"></span>
                <span className="bi bi-star-fill" data-rating="3"></span>
                <span className="bi bi-star" data-rating="4"></span>
                <span className="bi bi-star" data-rating="5"></span> */}
                <ReactStars
                    {...firstExample}
                />
            </div>
            <div className="testimonial-item">
                <p>{props.comments}<br />
                <br />
                <br />
                </p>
            </div>
            </div>
        </div>
    );

}
export default SliderComponent;