import React from 'react';
import axios from 'axios';
import $ from 'jquery';


import HomeCubeComponent from '../components/HomeCube';

//import '../../assets/vendor/swiper/swiper-bundle.min.css';
//import Swiper from '../../assets/vendor/swiper/swiper-bundle.min.js';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import SliderComponent from '../components/SliderSection';

function HomeComponent()
{
    const [post, setPost] = React.useState(null);
    const [testimonials, setTestimonials] = React.useState(null);
    let cubes=[];
    React.useEffect(() => 
    {
        axios.get('../../jData.json').then((response) => 
        {
          
          let res=response.data;
          let cubeData,testimonialsDiv=[];
          
          if(res.hasOwnProperty('home') && Object.keys(res).length>0)
          {
            if(res.home.hasOwnProperty('cube') && Object.keys(res.home.cube).length>0)
            {
                cubeData=res.home.cube;
                setPost(cubeData);
            }
            if(res.home.hasOwnProperty('testimonials') && Object.keys(res.home.testimonials).length>0)
            {
              testimonialsDiv=res.home.testimonials;
                setTestimonials(testimonialsDiv);
            }
          }
          
        });
      }, []);
     
      
        $(document).off().on('click', '.features_btn_viewall', function(e) 
        {  
            var btn_show = this; var btncount = 0;
            const parent = this.closest('.row');
            const children = parent.querySelectorAll(':scope > div.d-lg-block');
            children.forEach(element => {
              if (element.classList.contains('d-none')) {btncount++;
                element.classList.remove('d-none')
                element.classList.add('d-block')
                if (btncount == 1) {
                btn_show.innerHTML = 'View less';
                }
              } else if (element.classList.contains('d-block')) {btncount++;
                element.classList.remove('d-block')
                element.classList.add('d-none')
                if (btncount == 1) {
                btn_show.innerHTML = 'View all';
                }
              }
            });
            
        });
      
      if (!post) return ""
  return(
  <>
   <section id="hero" className="d-flex align-items-center">
    <div className="container" data-aos="zoom-out" data-aos-delay="100">
        <div className="row">
        <div className="content col-xl-12 text-center">
            <h1>Manage today for <br />
            <span>better tomorrow</span>
            </h1>
            <p>KalendarDay is a powerful app that offers corporate executives down to the project manager, the ability to view the performance of time and budget-controlled projects, collectively or individually</p>
            <p>These summaries can be viewed on a company wide level or on an individual project level, for whatever details they chose, through a customizable timesheet and project set up.</p>
            <div className="image-bg">
            <img src="assets/img/header-graphic-v08.gif" className="img-fluid" alt="Manager Dashboard" />
            </div>
        </div>
        </div>
    </div>
    </section>
    <main id="main">
    <section id="dashboard" className="dashboard lightgray-bg section-bg">
        <div className="container">
        <div className="row no-gutters">
            <div className="content col-xl-12">
            <h2>Manager’s dashboard</h2>
            <p>Sample of a manager’s dashboard that is customizable to reflect the specific details and requirements that a corporate manager, executive manager, regional manager, or a branch manager need to see.</p>
            <div className="image-bg">
                <img src="assets/img/Dashboard.png" className="img-fluid" alt="Manager Dashboard" />
            </div>
            </div>
        </div>
        </div>
    </section>
    <section id="employee-dashboard" className="employee-dashboard">
        <div className="container">
        <div className="row no-gutters">
            <div className="content col-xl-12">
            <h2>Employees dashboard</h2>
            <p>Sample employee dashboard that is customizable to reflect the employee’s needs, such as timesheets and employee relevant information.</p>
            <div className="image-banner-bg">
                <img src="assets/img/Employee.png" className="img-fluid" alt="Manager Dashboard" />
            </div>
            </div>
        </div>
        </div>
    </section>
    <section id="timesheet" className="dashboard lightgray-bg section-bg">
        <div className="container">
        <div className="row no-gutters">
            <div className="content col-xl-12">
            <h2>Timesheet</h2>
            <p>KalendarDay offers a flexible and customizable timesheet to allow precise and adjustable time controls that will be reflected on the project budget and controls to enable managers to evaluate to progress and budget of the projects.</p>
            <p>Timesheets can be divided into different categories based on the companies needs, such as budget chargeable time, overhead time and miscellaneous time.</p>
            <div className="image-bg">
                <img src="assets/img/Timesheet.png" className="img-fluid" alt="Time sheet" />
            </div>
            </div>
        </div>
        </div>
    </section>
    <section id="services" className="services section ">
        <div className="container">
        <div className="section-title">
            <p>KalendarDay has numerus unique features that enable managers of any level to have specific controls on projects including:</p>
        </div>
        <div className="row">
            
        {post.length>0 && post.map(function(data, i){
           return <HomeCubeComponent key={i} clName={data.class} imagePath={data.image} description={data.description} />;
        })}
            <button className="orange-btn features_btn_viewall mt-4 mb-4  ms-auto me-auto d-block d-lg-none">View all</button>
        </div>
        </div>
    </section>
    <section id="testimonials" className="testimonials">
      <div className="container">
        <div className="section-title">
            <h2>Hear what our customers say</h2>
        </div>
        <div className="testimonials-slider swiper">
            <div className="swiper-wrapper">
            <Swiper
               spaceBetween={10}
               modules={[Navigation, Pagination, Scrollbar, A11y]}
               pagination={{ clickable: true }}
               breakpoints={{
                  // when window width is >= 640px
                  640: {
                    width: 640,
                    slidesPerView: 1,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                }} 
              >
                
              {testimonials.length>0 && testimonials.map(function(data, i)
              {
                return <SwiperSlide><SliderComponent key={i} cname={data.name} stars={data.starts} info={data.post} imgpath={data.starts} comments={data.desc}></SliderComponent></SwiperSlide>;
              })}
              
             </Swiper> 
            </div>
            <div className="swiper-pagination"></div>
        </div>
        </div>
    </section>
    </main>  
  </>
  );
}
export default HomeComponent;