const comjson=
{
    "social":
    {
    "youtube":"https://youtube.com/channel/UCEnwvKr5m8S2IbSzfoothfQ",
    "facebook":"https://www.facebook.com/KalendarDay-101346115640099/",
    "instagram":"https://www.instagram.com/KalendarDayofficial/",
    "twitter":"https://twitter.com/KalendarDay",
    "linkedin":"https://www.linkedin.com/company/KalendarDay/"
    },
    "loginlink":"https://app.KalendarDay.com/",
    "price_api":"https://api.kalendarday.net/api/mainsite/subscription/plans",
    "api_link":"https://api.kalendarday.net/api/",
    //"api_link":"http://localhost/kaldendar-day/t-api/public/api/",
    
    
}
export default comjson;
